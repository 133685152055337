import { useState } from "react";

import { Modal, Upload } from "antd";

import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const PictureWallInput = ({
  fileList,
  updateFileList,
  limitFile = 8,
  multiple,
}: {
  fileList: UploadFile[];
  updateFileList: (files: UploadFile[]) => void;
  limitFile?: number;
  multiple?: UploadProps["multiple"];
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    updateFileList(newFileList);

  const uploadButton = (
    <div>
      <div style={{ marginTop: 8 }}>Tải lên</div>
    </div>
  );
  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        multiple={multiple}
        onPreview={handlePreview}
        accept={"image/*"}
        style={{ width: 50 }}
        beforeUpload={() => false}
        onChange={handleChange}>
        {fileList.length >= limitFile ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default PictureWallInput;
